import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class CvService {
  getAll(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/cvs?p=${page}&l=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/cvs?p=${page}&l=${limit}`, { headers: authHeader() });
    }
  }

  getMyCvData(user_id = null) {
    return axios.get(API_URL + `admin/cvs/my/${user_id}`, { headers: authHeader() });
  }

  getCvData(id) {
    return axios.get(API_URL + `admin/cvs/${id}`, { headers: authHeader() });
  }

  // intro

  getIntro(user_id) {
    return axios.get(API_URL + `admin/cvs/intro/${user_id}`, { headers: authHeader() });
  }

  saveIntro(data) {
    return axios.put(API_URL + `admin/cvs/intro/${data.id}`, {...data}, { headers: authHeader() });
  }

  // search index
  updateUserCv(userId) {
    return axios.get(API_URL + `admin/cvs/reindex/${userId}`, { headers: authHeader() });
  }

  reindex() {
    return axios.get(API_URL + `admin/cvs/reindex`, { headers: authHeader() });
  }

  // resumes

  getResumes(user_id) {
    return axios.get(API_URL + `admin/cvs/resumes/${user_id}`, { headers: authHeader() });
  }

  getResume(id) {
    return axios.get(API_URL + `admin/cvs/resumes/${id}/edit`, { headers: authHeader() });
  }

  saveResume(resume, projects) {
    return axios.put(API_URL + `admin/cvs/resumes/${resume.id}`, {...resume, projects}, { headers: authHeader() });
  }

  createResume(user_id, resume, projects) {
    return axios.post(API_URL + `admin/cvs/resumes/${user_id}`, {...resume, projects}, { headers: authHeader() });
  }

  removeResume(id) {
    return axios.delete(API_URL + `admin/cvs/resumes/${id}`, { headers: authHeader() });
  }

  // skills

  getSkills(user_id) {
    return axios.get(API_URL + `admin/cvs/skills/${user_id}`, { headers: authHeader() });
  }

  getSkill(id) {
    return axios.get(API_URL + `admin/cvs/skills/${id}/edit`, { headers: authHeader() });
  }

  saveSkill(skill) {
    return axios.put(API_URL + `admin/cvs/skills/${skill.id}`, {...skill}, { headers: authHeader() });
  }

  createSkill(user_id, skill) {
    return axios.post(API_URL + `admin/cvs/skills/${user_id}`, {...skill}, { headers: authHeader() });
  }

  removeSkill(id) {
    return axios.delete(API_URL + `admin/cvs/skills/${id}`, { headers: authHeader() });
  }

  // references

  getReferences(user_id) {
    return axios.get(API_URL + `admin/cvs/references/${user_id}`, { headers: authHeader() });
  }

  getReference(id) {
    return axios.get(API_URL + `admin/cvs/references/${id}/edit`, { headers: authHeader() });
  }

  saveReference(skill) {
    return axios.put(API_URL + `admin/cvs/references/${skill.id}`, {...skill}, { headers: authHeader() });
  }

  createReference(user_id, skill) {
    return axios.post(API_URL + `admin/cvs/references/${user_id}`, {...skill}, { headers: authHeader() });
  }

  removeReference(id) {
    return axios.delete(API_URL + `admin/cvs/references/${id}`, { headers: authHeader() });
  }

  // languages

  getLanguages(user_id) {
    return axios.get(API_URL + `admin/cvs/languages/${user_id}`, { headers: authHeader() });
  }

  getLanguage(id) {
    return axios.get(API_URL + `admin/cvs/languages/${id}/edit`, { headers: authHeader() });
  }

  saveLanguage(language) {
    return axios.put(API_URL + `admin/cvs/languages/${language.id}`, {...language}, { headers: authHeader() });
  }

  createLanguage(user_id, language) {
    return axios.post(API_URL + `admin/cvs/languages/${user_id}`, {...language}, { headers: authHeader() });
  }

  removeLanguage(id) {
    return axios.delete(API_URL + `admin/cvs/languages/${id}`, { headers: authHeader() });
  }

  // education

  getEducations(user_id) {
    return axios.get(API_URL + `admin/cvs/education/${user_id}`, { headers: authHeader() });
  }

  getEducation(id) {
    return axios.get(API_URL + `admin/cvs/education/${id}/edit`, { headers: authHeader() });
  }

  saveEducation(education) {
    return axios.put(API_URL + `admin/cvs/education/${education.id}`, {...education}, { headers: authHeader() });
  }

  createEducation(user_id, education) {
    return axios.post(API_URL + `admin/cvs/education/${user_id}`, {...education}, { headers: authHeader() });
  }

  removeEducation(id) {
    return axios.delete(API_URL + `admin/cvs/education/${id}`, { headers: authHeader() });
  }

  // training

  getTrainings(user_id) {
    return axios.get(API_URL + `admin/cvs/training/${user_id}`, { headers: authHeader() });
  }

  getTraining(id) {
    return axios.get(API_URL + `admin/cvs/training/${id}/edit`, { headers: authHeader() });
  }

  saveTraining(education) {
    return axios.put(API_URL + `admin/cvs/training/${education.id}`, {...education}, { headers: authHeader() });
  }

  createTraining(user_id, education) {
    return axios.post(API_URL + `admin/cvs/training/${user_id}`, {...education}, { headers: authHeader() });
  }

  removeTraining(id) {
    return axios.delete(API_URL + `admin/cvs/training/${id}`, { headers: authHeader() });
  }

  // projects

  getProjects(user_id) {
    return axios.get(API_URL + `admin/cvs/projects/${user_id}`, { headers: authHeader() });
  }

  getProject(id) {
    return axios.get(API_URL + `admin/cvs/projects/${id}/edit`, { headers: authHeader() });
  }

  saveProject(education) {
    return axios.put(API_URL + `admin/cvs/projects/${education.id}`, {...education}, { headers: authHeader() });
  }

  createProject(user_id, education) {
    return axios.post(API_URL + `admin/cvs/projects/${user_id}`, {...education}, { headers: authHeader() });
  }

  removeProject(id) {
    return axios.delete(API_URL + `admin/cvs/projects/${id}`, { headers: authHeader() });
  }

}

export default new CvService();
